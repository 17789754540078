body {
    font-family: $font-family-sans-serif;
}

h4 {
    color: $darkBlue;
    font-weight: bold;
}

.navbar-custom {
    background: rgba($white, 0.5);
}

.background-path {
    background-image: url("/images/bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.background-start {
    background-image: url("/images/app/2_Esercizio.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.description-start {
    width: 50%;
    float: right;
    padding: 5% 2% 5% 0;
    height: 100%;
}

.description-intro {
    display: inline-block;
    height: 60vh;
    margin: 9vh 0 9vh 5vw;
    width: 40%;
}

.preview-intro {
    height: 60vh;
    display: inline-block;
}

.text {
    font-size: 2.2vh;
}

.title {
    font-size: 2.6vh;
}

.container-vc {
    display: table;
    height: calc(100% - #{$nav-size});
    position: absolute;
    overflow: hidden;
    width: 100%;
}

.helper-vc {
    display: table-cell;
    vertical-align: middle;
}

.content-vc {
    margin: 0 auto;
    height: 80vh;
    width: 90%;
    //border: 10px double orange;
    //background: #fff9c0;
}

.fullscreen {
    height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    max-width: none !important;
}

.iframe-fullscreen {
    height: 100%;
    width: 100vw;
}

.company-logo {
    height: 30px;
}

#app {
    height: 100vh;
    overflow: hidden;
}

main {
    height: calc(100% - 42.8px);
}

.py-4 {
    padding: 0 !important;
}

.avatar {
    position: absolute;
    text-align: center;
}

.avatar-report {
    height: 44vh;
    display: block;
    margin: auto;
}

.svg-container {
    height: 44vh;
    svg {
        height: 100%;
    }
}

.avatar-text-left {
    border: 2px;
    border-style: solid;
    border-color: $energicBlue;
    padding: 0.1rem 0.8rem;
    background: $energicBlue;
    color: white;
    border-radius: 0.5rem;
    display: inline-block;
}

.avatar-text-right {
    border: 2px;
    border-style: solid;
    border-color: $energicBlue;
    color: $energicBlue;
    padding: 0.1rem 0.8rem;
    background: white;
    border-radius: 0.5rem;
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-weight: bold !important;
}

.progress-sidebar {
    max-width: 200px;
    margin-left: auto;
    background: white;
    display: inline-block;
    float: right;
    border: $darkBlue solid 0.5em;
    border-right: unset;
    border-radius: 16px 0 0 16px;
    color: white;
    font-family: 'Lato', sans-serif;
    font-weight: bold !important;
    text-align: center;
}

.level-sidebar {
    border: 3px;
    border-style: solid;
    padding: 1em;
    background: $coolLightBlue;
    border-radius: 12px;
    display: inline-block;
    width: 110px;
    text-align: center;
    margin: 0.5em 10px 0.5em 10px;
    pointer-events: none;
    font-size: 12px;
}

.level-completed {
    background: $darkBlue;
    cursor: pointer;
    pointer-events: all;
}

.level-current {
    background: $energicBlue;
    cursor: pointer;
    pointer-events: all;
}

.av-pos-1 {
    $offset-top: 10vh;
    $offset-left: -27vh;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + #{$offset-top}), calc(-50% + #{$offset-left}));
    .avatar-img {
        height: 13vh;
    }
}

.av-pos-2 {
    $offset-top: 3vh;
    $offset-left: -15vh;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + #{$offset-top}), calc(-50% + #{$offset-left}));
    .avatar-img {
        height: 22vh;
    }
}

.av-pos-3 {
    $offset-top: -38vh;
    $offset-left: -14vh;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + #{$offset-top}), calc(-50% + #{$offset-left}));
    .avatar-img {
        height: 27vh;
    }
}

.av-pos-4 {
    $offset-top: -49vh;
    $offset-left: -5vh;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + #{$offset-top}), calc(-50% + #{$offset-left}));
    .avatar-img {
        height: 27vh;
    }
}

.av-pos-5 {
    $offset-top: -18vh;
    $offset-left: 21vh;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + #{$offset-top}), calc(-50% + #{$offset-left}));
    .avatar-img {
        height: 36vh;
    }
}

.av-pos-6 {
    $offset-top: 22vh;
    $offset-left: 26vh;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + #{$offset-top}), calc(-50% + #{$offset-left}));
    .avatar-img {
        height: 43vh;
    }
}

.av-pos-7 {
    $offset-top: 38vh;
    $offset-left: 27vh;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + #{$offset-top}), calc(-50% + #{$offset-left}));
    .avatar-img {
        height: 43vh;
    }
}

.toast {
    position: absolute;
    top: 50px;
    border-radius: 10px;
    border: 3px solid $colorRed;
    background: white;

    .toast-header {
        background: rgba($colorRed, 0.1);
    }
    .toast-body {
        padding: 1.5rem;
    }
}

.main-box {
    //background: hsl(197deg 79% 70%);
    background-color: white;
    border: 0.4vh solid white;
    box-shadow: 5px 5px 15px 0px #666666;
    border-radius: 17px;
    padding: 0.7vh;
    margin: 2vh 0;
}

.title-container {
    color: white;
    height: 4vh;
    display: flex;
    align-items: center;
}

.box-title {
    border-radius: 40px;
    background: #1fabe5;
    width: 83%;
    display: inline-block;
    padding-left: 4%;
    font-size: 2vh;
    margin-left: 2%;
}

.fi {
    border-radius: 40px;
}

.bottom-logo {
    position: absolute;
    height: 40px;
    bottom: 15px;
    left: 15px;
}

.start-container {
    float: right;
    width: 54%;
    padding: 2%;
    margin-right: 3%;
    text-align: center;
}

.nice-checkbox {
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 5px;
    border: 2px solid #555;
    vertical-align: middle;
    margin-left: 5px;
}

.btn-start {
    background: $energicBlue;
    color: white;
}

.navbar-center {
    margin: auto;
}

.vertical-alignment-helper {
    display: table;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events: none;
}

.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width: inherit;
    max-width: inherit;
    /* For Bootstrap 4 - to avoid the modal window stretching full width */
    height: inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events: all;
    box-shadow: 0px 0px 15px #fff;
    border: #00145A solid 5px;
    border-radius: 20px;
    text-align: center;
}

.modal-header {
    text-align: center;
    border: unset;
}

.modal-title {
    width: 100%;
    font-weight: bold !important;
    margin-left: 30px;
}

//.modal-backdrop {
    //background-color: rgba(0, 0, 0, .0001) !important;
//}

.close {
    border-radius: 50%;
    padding: 0.5em;
    width: 30px;
    height: 30px;
    border: 2px solid $energicBlue;
    background-color: $energicBlue;
    position: relative;
}

.close::before {
    content: " ";
    position: absolute;
    display: block;
    background-color: white;
    width: 2px;
    left: 12px;
    top: 5px;
    bottom: 5px;
    transform: rotate(45deg);
}

.close::after {
    content: " ";
    position: absolute;
    display: block;
    background-color: white;
    height: 2px;
    top: 12px;
    left: 5px;
    right: 5px;
    transform: rotate(45deg);
}

.text-blue-up {
    text-transform: uppercase;
    color: $energicBlue;
}

.text-blue {
    color: $energicBlue;
}

.navbar-line-blue {
    box-shadow: 0 0.425rem 0.25rem $darkBlue !important
}

.result-avatar {
    text-align: center;
    .title {
        font-size: 4.2vh;
    }
    img {
        margin: auto;
        height: 40vh;
    }
    /* lg */
    @media (min-width: 1200px) {
        img {
            height: 60vh;
        }
    }
    span {
        width: 73%;
        margin: auto;
    }
    strong,
    img,
    span {
        display: block;
    }
}

.myNik {
    background: rgba(255,255,255, 0.5);
    padding: 1vh 2vh;
    border-radius: 4px;
    font-weight: bold;
}

.completed-title {
    font-size: 3vh;
    margin-bottom: 5vh;
}


/* varie jack */

.btn {
    box-shadow: 3px 3px 5px 0px $grey;
}

.btn-primary:hover {
    color: $secondary;
}

.help-popup {
    
    .help-popup-content--gray {
        color: gray;
        font-size: 0.9rem;

        a {
            color: gray;
            font-weight: bold;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}


.faqs-page {
    .faqs-title-page {
        span {
            font-size: 1.2rem;
        }
    }
    .faq-title {
        font-weight: bold;
        color: $darkBlue;
        background-color: white;
    }
    .faq-content {
        font-size: 1rem;
        background: #e1ecf7;
        line-height: 1.5rem;
    }
}