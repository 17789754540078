// Colors
// Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Flag Icon CSS
@import '~flag-icons/sass/flag-icons';
// Font Awesome
$fa-font-path: "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import 'theme';