// Body
$body-bg: #f8fafc;
// Typography
$font-family-sans-serif: 'Lato',
sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
$darkBlue: #00145A;
$coolLightBlue: #C6D9DA;
$energicBlue: #1D57FB;
$colorRed: #c00;
$grey: #666666;
$nav-size: 56.8px;

/* da bootstrap base */

$primary: #1D57FB;
$secondary: #C6D9DA;
$navbar-padding-y: 0 !default;
$btn-padding-y: 0.1rem !default;